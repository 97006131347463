<template>
  <div class="courseware-item-container">
    <div
      class="isWatch_courseware"
      v-if="item.meet_count >= item.courseware_max_count"
    >
      该课件次数已用完
    </div>
    <img class="courseware-item-cover" :src="item.cover_url" alt="" />
    <div class="courseware-item-desc">{{ item.courseware_title }}</div>
    <div class="courseware-item-footer">
      <div class="courseware-item-icon global-center">
        <img
          :src="item.logo_url || require(`@/assets/img/pptLogo.png`)"
          alt=""
        />
      </div>
      <div class="courseware-item-date">
        {{ $tools.getDate() }}&nbsp;&nbsp;&nbsp;&nbsp;{{
          $tools.getDayName(item.publish_date)
        }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "courseware-item",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
};
</script>
<style>
.courseware-item-container {
  border-radius: 10px;
  background: #fff;
  position: relative;
}
.courseware-item-cover {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.courseware-item-desc {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  color: #606060;
  padding: 5px;
}
.courseware-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 9px;
  color: #606060;
  padding: 5px;
}
.courseware-item-icon {
  width: 24px;
  width: 24px;
  border-radius: 24px;
  background: rgb(252, 226, 222);
}
.courseware-item-icon img {
  width: 21px;
  height: auto;
  border-radius: 21px;
}
.isWatch_courseware {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(121, 112, 112, 0.5);
  z-index: 999;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>