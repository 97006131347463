<template>
  <div class="courseware-list-container full-screen">
    <ItemScroll @pullingUp="pullingUp" ref="ItemScroll">
      <div class="courseware-list-main">
        <CoursewareItem
          @click="goToDetail(item)"
          class="courseware-list-li"
          v-for="(item, index) in dataList"
          :key="index"
          :item="item"
          >{{ index }}</CoursewareItem
        >
      </div>
    </ItemScroll>
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import CoursewareItem from "./coursewareItem";
export default {
  props: {
    searchDate: {
      type: Object,
      default: {},
    },
  },
  name: "courseware-list",
  data() {
    return {
      search: {
        page: 1,
        pagesize: 20,
      },
      dataList: [],
    };
  },
  components: {
    ItemScroll,
    CoursewareItem,
  },
  created() {
    console.log("searchDate :>> ", this.searchDate);
    if (!this.searchDate.page) {
      this.loadData();
    }
  },
  methods: {
    async loadData(data = this.search) {
      let url = this.$tools.getURL(this.$urls.courseware.list, {
        ...data,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          this.count = Math.ceil(res.count / this.search.pagesize);
          this.dataList = this.dataList.concat(res.data);
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.ItemScroll.initScroll();
            }, 1000);
          });
        })
        .catch(() => {});
    },
    pullingUp() {
      this.search.page += 1;
      let data = {
        ...this.search,
        ...this.searchDate,
        field: this.searchDate.field == -1 ? "" : this.searchDate.field || "",
        page: this.search.page,
      };
      if (data.page <= this.count) {
        this.loadData(data);
      }
    },
    goToDetail(item) {
      if (item.meet_count >= item.courseware_max_count) return;
      this.$emit("getStorageDate");
      let id = this.$store.state.common.userId; //医生登录
      console.log("id :>> ", id);
      //会议进 有form  确认选择/courseware/detail
      //列表进  销售 专家一键备课/courseware/preview
      if (this.$route.query.form) {
        this.$router.push({
          path: "/courseware/detail",
          query: {
            id: item.id,
            ...this.$route.query,
          },
        });
      } else {
        this.$router.push({
          path: "/courseware/preview",
          // path: "/courseware/detail",
          query: {
            id: item.id,
          },
        });
      }

      // if (!this.$route.query.form) {
      //   //销售\
      //   this.$router.push({
      //     path: "/courseware/preview",
      //     // path: "/courseware/detail",
      //     query: {
      //       id: item.id,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/courseware/detail",
      //     query: {
      //       id: item.id,
      //       ...this.$route.query,
      //     },
      //   });
      // }
    },
  },
};
</script>
<style>
.courseware-list-container {
  overflow: hidden;
  margin-top: 10px;
  flex: 1 0 0;
}
.courseware-list-main {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.courseware-list-li {
  width: calc(100% / 2 - 2.5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.courseware-list-li:nth-child(2n) {
  margin-left: 5px;
}
</style>