<template>
  <div class="courseware_list_container">
    <div class="courseware_list_search">
      <div class="courseware_list_select">
        <el-select
          v-model="search.field"
          popper-class="select_option"
          @change="searchData"
        >
          <el-option label="全部" value="-1" />
          <el-option
            v-for="item in fieldList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <!-- <el-select
          v-model="search.periods"
          popper-class="select_option"
          @change="searchData"
        >
          <el-option label="全部" value="0" />
          <el-option
            v-for="item in periodsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
      </div>
      <div class="courseware_list_search_input">
        <el-input
          v-model.trim="search.key_word"
          placeholder="请输入关键词"
          @keyup.enter="searchData"
          @blur="searchData"
        >
          <template #suffix>
            <el-icon class="el-icon-search" @click="searchData"
              ><Search
            /></el-icon>
          </template>
        </el-input>
      </div>
    </div>
    <div class="courseware_list_conent">
      <div class="courseware_list_conent_title">课件列表</div>

      <courseware :searchDate="search" ref="listScrollRef"></courseware>
    </div>
  </div>
</template>

<script>
import courseware from "./components/list.vue";
import { Search } from "@element-plus/icons";
export default {
  name: "list",
  components: {
    courseware,
    Search,
  },
  data() {
    return {
      search: {
        key_word: "",
        field: "-1",
        // periods: "0",
        page: 1,
        pagesize: 20,
      },
      periodsList: [],
      fieldList: [],
      data: [],
      timer: null,
      rotate: false,
    };
  },
  created() {
    // this.getPeriodData();
    this.getFieldData();
  },
  mounted() {
    this.searchData();
  },
  methods: {
    searchData() {
      this.$refs.listScrollRef.dataList = [];
      this.$refs.listScrollRef.search = {
        page: 1,
        pagesize: 20,
      };
      this.$refs.listScrollRef.loadData({
        ...this.search,
        field: this.search.field == -1 ? "" : this.search.field,
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.ItemScroll.initScroll();
        }, 1000);
      });
    },
    getPeriodData() {
      this.$axios
        .get(this.$urls.literature.periodsList)
        .then((res) => {
          this.periodsList = res.data.map((num) => {
            let year = num.split("-")[0];
            let month = parseInt(num.split("-")[1]);
            return {
              value: num,
              label: `${year}-第${month}期`,
              // label: `${year}-第${this.$tools.intToChinese(month)}期`,
            };
          });
          console.log(" this.periodsList :>> ", this.periodsList);
        })
        .catch(() => {});
    },
    getFieldData() {
      this.$axios
        .get(this.$urls.literature.fieldList)
        .then((res) => {
          this.fieldList = res.data;
        })
        .catch(() => {});
    },
    handleRefresh() {
      if (this.timer) return;
      this.rotate = true;
      //   this.$refs.listScrollRef.scroll.scrollTo(0, 0);
      //   this.$refs.listScrollRef.pullingDownHandler(true);
      this.timer = setTimeout(() => {
        this.rotate = false;
        this.timer = null;
      }, 1000);
    },
    handleDetail(item) {
      this.$router.push({
        path: "/articleListItem",
        query: {
          id: item.id,
        },
      });
    },
    handleLike() {},
    goToInfo() {},
  },
};
</script>

<style >
.courseware_list_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 21px;
  background-image: url("../../../assets/img/register-bg.png");
  background-size: 100% 110%;
  background-repeat: no-repeat;
}
.courseware_list_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.courseware_list_search .courseware_list_search_input {
  /* position: relative; */
}
.courseware_list_search .courseware_list_search_input .el-input {
  /* position: absolute;
  bottom: 0;
  right: 0; */
  width: 211px;
  height: 30px;
  line-height: normal !important;
}
.courseware_list_search_input input {
  height: 30px;
  line-height: normal !important;
  font-size: 14px !important;
  border-radius: 2rem;
}
.courseware_list_search_input input::-webkit-input-placeholder {
  line-height: normal !important;
  font-size: 14px !important;
}
.courseware_list_search .el-input__suffix {
  font-size: initial;
  display: flex;
  align-items: center;
  right: 3.5vw;
  line-height: 30px;
}
.courseware_list_search .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
}
.courseware_list_search .el-input__suffix-inner,
.courseware_list_search .el-select .el-input .el-select__caret {
  height: 30px;
  line-height: 30px;
}
.courseware_list_select {
  display: flex;
  align-items: end;
  justify-content: end;
  height: 100%;
}
.courseware_list_select .el-select {
  width: 140px !important;
}

.courseware_list_conent {
  /* background: #fff; */
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}

.courseware_list_conent_title {
  font-weight: bold;
  font-size: 14px;
  color: #f26649;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
}
.courseware_list_container .el-input__inner {
  background-color: rgba(255, 255, 255, 0.5);
}
.courseware_list_container .el-select__wrapper {
  min-height: 30px !important;
}
.courseware_list_container .el-input__inner {
  padding-left: 2px;
}
</style>